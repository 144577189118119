<template>
  <footer class="footer">
      <img src="@/assets/logo/oe-logo.svg" alt="logo On Earth Srl">
      <ul>
          <li>
              <Svg className="footer--svg" icon="home"/> Chaussée de waterloo - B-1180 Uccle - Belgique
          </li>
          <li>
              <a href="tel:0032497331008">
                  <Svg className="footer--svg" icon="phone"/>+32 497 33 10 08
                </a>
                <a href="mailto:info@onearth.be">
                    <Svg className="footer--svg" icon="email"/>info@onearth.be
                </a>
          </li>
          <li>
              tva: be.0763.581.921
          </li>
      </ul>
      
  </footer>
</template>

<script>
import Svg from "./utils/Svg.vue";
export default {
    components: {
        Svg
    }

}
</script>

<style lang='scss'>
@import '@/style/main';
.footer{
    width: 100%;
    padding: 4rem 0;

    border-top: 3px solid $color-text;

    display: flex;
    flex-direction: column;
    align-items: center;

    & img{
        width: 8rem;
        height: auto;
        margin-bottom: 1.6rem;
    }

    & a{
        @include buttonNoStyle;
        display: flex;
        align-items: center;

        &:not(:last-child){
            margin-right: 1.5rem;
            &::after{
                margin-left: 1.5rem;
                content:"-"
            }
        }
    }

    & ul{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    & li{
        display: flex;
        list-style: none;
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: .1rem;

        &:not(:last-child){
            margin-bottom: 1.2rem;
        }
    }


    &--svg{
        @include svg(1.6rem);
        margin-right: 1rem;
    }
}

</style>