export const smoothScroll = (anchor) => {
  let target = document.getElementById(anchor);
  let headerOffset = document.getElementById("nav").offsetHeight;
  let elementPosition = target.offsetTop;
  let offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};
