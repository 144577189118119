<template>
  <header id="header" class="header">
      <div class="header__img">
        <img src="@/assets/img/header.png" alt="">
      </div>
      <div class="header__content">
        <h1>We are building the world of tomorrow</h1>
        <h2>
          <b>On Earth</b> is a digital company that develops solutions to recurring problems thanks to web technologies.
        </h2>
        <p>
          On Earth was built in februari 2021 with a simple mission: to solve meaningful problems thanks to computer science. Founded by Sébastien Goldberg, a nature lover, we use the latest technologies to make the world easier and a better place to live. 
        </p>
        <button class="btn btn--white" @click="smoothScroll('content')">
          Discover our activities
        </button>
      </div>
  </header>
  <div id="content">
    <container v-for="(website, i) in websites" :key="website.logo" :data="website" :index="i"></container>
  </div>
  
</template>

<script>
// @ is an alias to /src
import Container from '@/components/Container.vue'
import {smoothScroll} from '@/composables/scroll';

export default {
  name: 'Home',
  components: {
    Container
  },
  setup(){
    const websites = [
      {
        logo: "doe-logo.svg",
        image: "doe-illustration.png",
        title: "Digital On Earth",
        subtitle: "Business automation through web development",
        p: [
          "Digital On Earth is born in 2021 after the constatation that many businesses still rely on old processes that should be updated to stay up to date with the current technologies.",
          "Our purpose is to develop tools that enables these business to focus on their added value and let the other part to computers which are faster and 100 times more reliables than humans.",
          "The expertise of Digital On Earth is focused on web technologies, starting projects from early idea stage until the end where the project is running on live servers. If you want to know more about the methologies and tools used, just follow the link below.",
        ],
        cta:{
          text:"digital.onearth.be",
          link: "https://digital.onearth.be"
        }
      },
       {
        logo: "hoe-logo.svg",
        image: "hiker.png",
        title: "Hike On Earth",
        subtitle: "Find your next hiking adventure",
        p: [
          "Hike On Earth is born in 2018 after a long distance hike on the famous Pacific Crest Trail. This hiking experience was extraordinary but remains still quite inaccessible to most people. Why ? Because you hiking into the wild for days is hard and can be dangerous if you are not well prepared.",
          "On the basis of this observation, we wanted to make hiking more accessible to everyone. We created hikeonearth.com, a web platform that gathers hikers around the world and help each other organizing their next hiking adventure, however their level of experience.",
        ],
        cta:{
          text:"hikeonearth.com",
          link: "https://www.hikeonearth.com"
        }
      },
       {
        logo: "wokal.svg",
        image: "wokal.jpg",
        title: "Wokal - Your Local Coworking",
        subtitle: "Wokal is a fully automated coworking space in the South of Brussels",
        p: [
          "WOKAL is a coworking space located in Alsemberg (Beersel), 50 meters from Alsembergsteenweg, only 10 minutes from Brussels.",
          "With a capacity of 8 people, our space is perfect if you are looking for a dynamic and friendly atmosphere to work close from home.",
          "We put at your disposal a professional meeting room able to accommodate 10 people, spacious and comfortable offices, a phone-box, a garden for the beautiful days as well as a bathroom for the most sportive among you."
      ],
        cta:{
          text:"wokal.be",
          link: "https://www.wokal.be"
        }
      }
    ]
    return {
       smoothScroll,
      websites
    }
  }
}
</script>

<style lang="scss">
@import '@/style/main';

.header{
    margin : calc(#{$nav-height} + 4rem) auto 4rem auto;
    position: relative;
    display: flex;
    align-items: center;
    border: 2px solid $color-blue-dark;
    max-width: $page-width;
    padding: 4rem;
    box-shadow: $shadow;

    color:$color-text;

    @include respond(phone){
      flex-direction: column;
    }

    &__img{
      flex: 0 0 35%;
      margin-right: 3rem;

      @include respond(phone){
        margin-right: 0;
        margin-bottom: 3rem;
      }

      & img{
        width: 100%;
        height: auto;
      }
    }

    &__content{
      & h1{
        font-size: 3.4rem;
        letter-spacing: .2rem;
        text-transform: uppercase;
        margin-bottom: 1.2rem;
      }

      & h2{
        font-weight: 400;
        margin-bottom: 3rem;
      }

      & p{
        font-weight: 400;
        letter-spacing: .1rem;
        margin-bottom: 3rem;
        text-align: justify;
      }
    }
    
}


</style>
