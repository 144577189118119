<template>
  <navigation></navigation>
  <router-view/>
  <Footer />
</template>

<script>
import Navigation from '@/components/Navigation';
import Footer from '@/components/Footer';

export default {
  components:{
    Footer,
    Navigation
  },
  setup() {
    
  },
}
</script>



<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');
@import 'style/main';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
}


#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;

  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
	padding: 0rem; /*Mets une bordure au site*/

  ::selection {
    background-color: $color-blue-main;
    color: $color-white;
  }

}
</style>
