<template>
    <nav id="nav" class="nav">
        <div class="nav__content">
            <div class="nav__logo">
                <img src="@/assets/logo/oe-logo.svg" alt="logo On Earth" class="nav__logo--img">
            </div>
            <div class="nav__items">
                <a href="tel:0032497331008" class="btn__text nav__items--item u-phone-hide">
                    <Svg icon="phone" className="nav__items--svg"/>
                    +32 497 33 10 08
                </a>
                <a href="mailto:info@onearth.be" class="btn__text nav__items--item">
                    <Svg icon="email" className="nav__items--svg"/>
                    info@onearth.be
                </a>
                <!-- <div class="nav__lang u-phone-hide">
                    <button class="btn__text nav__lang--item">
                        Fr
                    </button>
                    <button class="btn__text nav__lang--item" >
                        En
                    </button>
                </div> -->
            </div> 
        </div>
    </nav>

</template>

<script>
import { computed, ref } from 'vue'
import Svg from './utils/Svg'
import {smoothScroll} from '@/composables/scroll';

import {useStore} from 'vuex';
import {useRoute} from 'vue-router';

export default {
    components:{Svg},
    setup(){
        const displaySide = ref(false);
        const store = useStore();
        const route = useRoute();


        console.log(route.name);

        const scrollTo = (target) => {
            smoothScroll(target);
            hideSide();
        }

        const hideSide = () => {
            displaySide.value =false;
        }

        const setLanguage = (lang) => {
            locale.value = lang;

            store.state.language = lang;

            //Router
            route.query.locale = lang;
            history.pushState({}, null,  route.path+'?locale='+lang)
        }

        const menuSide = computed(() =>[
            {
                "text": t('how'),
                "target": "how"
            },
            {
                "text": t('solutions'),
                "target": "solutions"
            },
            {
                "text": t('method'),
                "target": "method"
            },

              {
                "text": t('portfolio'),
                "target": "portfolio"
            },
            {
                "text": t('faq'),
                "target": "faq"
            },
             {
                "text": t('about'),
                "target": "about"
            },
            
        ])

        return {displaySide, hideSide, menuSide, scrollTo}
    }
}
</script>

<style lang="scss">
@import '@/style/main';


.nav{
    height: $nav-height;
    box-shadow: $shadow;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 10;

    &__content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $page-width;
        margin: 0 auto;
        padding: 1.2rem 0;
        height: 100%;

            @include respond(tab-land){
                width: 95%;
            }
            @include respond(phone){
                width: 92%;
            }
    }

    &__menu{
        
        @include buttonNoStyle;
        align-self: stretch;
        display: flex;
        align-items: center;
        margin-right: 2rem;

        @include respond(phone){
            margin-right: 0;
        }
      

        &--bar{
            position: relative;
            width: 3rem;
            height: 3px;
            background-color: currentColor;
            border:none;padding: 0;
            &::before{
                content:"";
                position: absolute;
                left: 0;
                top: -1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
                
            }
            &::after{
                content:"";
                position: absolute;
                left: 0;
                top: 1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
            }

        }

        &:hover{
            color:$color-blue-main;
        }

        &:hover > &--bar{
            &::before{
                top: -1.5rem;
            }
            &::after{
                top: 1.5rem;
            }
        }
    }


    &__logo{
        height: 100%;
        width: auto;

        &--img{
            height: 100%;
            width: auto;
        }

    }

    
    &__lang{
        border-left: 1px solid currentColor;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;

        &--item{
            &:not(:last-child){
                margin-bottom: .6rem;
            }
        }
    }

    &__items{
        height: 100%;

        display: flex;
        align-items: center;

        &--item{
            display: flex;
            align-items: center;
            &:not(:last-child){
                margin-right: 3rem;
            }
        }

        &--svg{
            @include svg(2rem);
            margin-right: 1rem;
        }
        
    }

   
}

</style>