<template>
  <div class="container">
      <div v-if="index != 0" class="container__link container__link--up"></div>
      <div class="container__image">
          <a :href="data.cta.link" class="container__image--logo">
              <img :src="require(`@/assets/logo/${data.logo}`)" alt="">
          </a>
          <img :src="require(`@/assets/img/${data.image}`)" alt="">
      </div>
      <div class="container__content">
              <h2>
                  {{data.title}}
              </h2>
              <h3 v-if="data.subtitle">
                  {{data.subtitle}}
              </h3>
              <p v-for="paragraph in data.p">{{paragraph}}</p>
              <a :href="data.cta.link" class="btn btn--dark" target="_blank">
                  <Svg className="container__content--svg" icon="link"/> {{data.cta.text}}
              </a>
          
          <slot/>
      </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';
export default {
    props:['data', 'index'],
    components: {
        Svg
    }
}
</script>

<style lang="scss">
    @import '@/style/main';
    .container{
       position: relative;
       display: flex;
       width: 100%;
       max-width: $page-width;
       margin: 0 auto;
       padding: 6rem 0;

       @include respond(phone){
        flex-direction: column;
        padding: 6rem 3rem;
        }

       &__image{
           position: relative;
           flex: 0 0 25%;
            margin-right: 4rem;
            @include respond(phone){
                margin-right: 0;
                margin-bottom: 3rem;
            }

           & img{
               width: 100%;
               height: auto;
           }

           &--logo{
               position: absolute;
               top: 0;
               left: 50%;
               background-color: $color-white;
               z-index: 5;

               width: 8rem;
               height: 8rem;
               padding: .8rem;

               display: flex;
               align-items: center;
               justify-content: center;
               border: 1px solid $color-text;
               box-shadow: $shadow-light;
                
                transform: translate(-50%, -30%);
            }
       }
        
        &__content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include respond(tab-land){
                width: 95%;
            }
            @include respond(phone){
                width: 92%;
            }
            @include respond(large-desktop){
                max-width: $page-width-large;
            }

            & h2{
                text-transform: uppercase;
                font-weight: 700;
                font-size: 2.8rem;
                letter-spacing: .2rem;
                margin-bottom: .6rem;
            }

            & h3{
                font-weight: 300;
                font-size: 1.8rem;
                letter-spacing: .1rem;
                margin-bottom: 2rem;
            }

            & p{
                margin-bottom: 2rem;
                text-align: justify;
            }

            &--svg{
                @include svg(2rem);
                margin-right: 1.5rem;
            }
        }


        &__link{
                position: absolute;
                left: 50%;
                width: 12rem;
                height: .2rem;
                background-color: $color-text;
                z-index: 5;
           
            &--up{
                top: 0;
                transform: translate(-50%, -50%);
            }
        }
    }
</style>